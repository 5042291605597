.product-text-field {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-bottom: 10px;
}
.product-image-field {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-bottom: 10px;
}
.product-text-field-row {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .product-text-field {
    display: flex;
    flex-direction: column;
  }
  .product-image-field {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .product-text-field {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .product-image-field {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-bottom: 10px;
  }
}

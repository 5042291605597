.orders-div {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  height: 100vh;
}
.orders-list{
  width: 100%;
  height: 100%;
}

.order_main_content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.order-detail-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.order-detail-element {
  display: flex;
  gap: 5px;
  flex-direction: row;
  grid-gap: 10px;
  justify-content: flex-start;
  width: 50%;
}
.order-details {
  display: flex;
  flex-direction: column;
}
.order-addon-h6{
  font-size: 0.8rem;
}
.order_update_btn_div{
  display: flex;
  justify-content: center;
}


@media (min-width: 768px) and (max-width: 1024px) {
  /* .orders-div {
    display: flex;
    flex-direction: column;
  }
  .order-detail-element {
    justify-content: space-between;
  } */
}

@media (min-width: 320px) and (max-width: 480px) {
  .order-detail-row {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .order-detail-element {
    display: flex;
    gap: 5px;
    flex-direction: row;
    grid-gap: 10px;
    justify-content: space-between;
    width: 100%;
  }
  .order-addon-h6{
    font-size: 0.7rem;
}

}

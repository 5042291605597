.listgroupitem-row {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between;
  padding: 15px; */
  width: 100%;
}
.eachitem-div {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
 
}
.eachitem-div h4 {
  font-size: 0.875rem;
  display: flex;
}
.basic-details-button {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
}

.form-color-div {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding-top: 10px;
}
.form-color {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.form-color-input {
  width: 150px;
}
.imageKit-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 20px;
}
.each-Image-kit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
/* .title-providername{
    font-size: 0.8;
} */

/* 
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) {
  .listgroupitem-row {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  .eachitem-div {
    justify-content: space-between;
    font-size: 0.6rem;
    padding-bottom: 5px;
    width: 100%;
  }
  .eachitem-div h4 {
    font-size: 0.8rem;
  }
  /* .title-providername{
        font-size: 0.6rem;
    } */
}

/* 
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .listgroupitem-row {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  .eachitem-div {
    justify-content: space-between;
    font-size: 0.6rem;
    padding-bottom: 5px;
    width: 100%;
  }
  .eachitem-div h4 {
    font-size: 0.875rem;
  }
  .form-color-div {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    /* justify-content: start; */
    /* align-items: center; */
    padding-top: 10px;
  }
  .form-color {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .form-color-input {
    width: 150px;
  }
  .imageKit-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    justify-content: center;
    align-items: center;
  }
  .each-Image-kit {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 0.6rem;
    padding: 10px;
  }
  /* .title-providername{
    font-size: 0.6rem;
} */
}

.each-view-row {
  display: flex;
  width: 100%;
  align-items: center;
}
.each-element {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
  justify-content: flex-start;
  width: 40%;
  align-items: center;
}
.each-view-row-heading{
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  gap: 100px;
}
.suscription_date{
  font-size: 0.9rem;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .each-view-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  .each-element {
    display: flex;
    gap: 10px;
    font-size: 0.8rem;
  }
  .suscription_date{
    font-size: 0.68rem;
  }
  
}

@media (min-width: 320px) and (max-width: 480px) {
  .each-view-row {
    display: flex;
  width: 100%;
  align-items: flex-start;
  }
  .each-element {
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
    width: 100%;
  }
  .suscription_date{
    font-size: 0.75rem;
  }
  
}

.branch-add-form-div {
  display: flex;
  flex-direction: column;
 
}
.branch-text-field {
  display: flex;
  margin: 10px;
  gap: 5px;
}
.branch-text-field-row {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
}
.eachWorking-hours {
  display: flex;
  gap: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
}
.view-branch {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.each-view-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.each-element {
  display: flex;
  gap: 10px;
  font-size: 0.875rem;
}
.branch-modifiers-table {
}

.branch-products-table {
}
#myInput {
  width: 380px;
  border: none;
  cursor: pointer;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .view-branch {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .each-view-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  .each-element {
    display: flex;
    gap: 10px;
    font-size: 0.875rem;
  }
  #myInput {
    width: 200px;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .branch-add-form-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .branch-text-field {
    display: flex;
    flex-direction: column;
  }
  .view-branch {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .each-view-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  .each-element {
    display: flex;
    gap: 10px;
    font-size: 0.775rem;
  }
  .branch-modifiers-table {
  }

  .branch-products-table {
  }
  #myInput {
    width: 200px;
  }
}

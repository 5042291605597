.register-text-field {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
}

.register-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.subscription-plan-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}

.plan-div {
  display: flex;
  gap: 5px;
}

.sub-plan {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #a9bcb0;
  padding: 10px;
  border-radius: 10px;
  gap: 10px;
  max-width: 50%;
}

.plan-name {
  font-size: 0.7rem;
}

.plan-amt {
  font-size: 0.9rem;
}

.plan-des {
  font-size: 0.8rem;
  min-height: 40px;
}

.subscription-main-div {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(236, 242, 255);
}

.subscription-div {
  max-width: 600px;
  /* height: 700px; */
  padding: 20px;
  background: white;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .register-text-field {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .register-text-field {
    display: flex;
    flex-direction: column;
  }

  .subscription-plan-div {
    padding-top: 20px;
  }
}
.logout-page-div{
   display: none;
}
  
  @media (min-width: 768px) and (max-width: 1024px) {
    
  }
  
  @media (min-width: 320px) and (max-width: 480px) {
    .logout-page-div{
        display: block;
        text-align: center;
        margin-top: 30px;
    }
  }
  
.Addons-text-field {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-bottom: 10px;
}
.Addons-text-field-row {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.addon-div {
  /* height: 100vh; */
  /* overflow-y: scroll; */
}

@media (min-width: 768px) and (max-width: 1024px) {
  .Addons-text-field {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .Addons-text-field {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .addon-div {
    /* height: 100vh; */
    overflow-y: scroll;
  }
}
